import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_CLIENT_HTTP_LINK,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_HEALTH_CLIENT_HTTP_LINK,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_V1_CLIENT_HTTP_LINK,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_STAKING_CLIENT_HTTP_LINK,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: process.env.REACT_APP_BLOCK_CLIENT_HTTP_LINK,
  }),
  cache: new InMemoryCache(),
})
